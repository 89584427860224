import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Hero, Intro, Accomodations, Events, Rsvp } from "../components"

const IndexPage = () => {
  const { heroImage, sectionImage, introImage } = useImageData()

  return (
    <Layout>
      <Seo title="Home" />

      <div className="bg-white">
        <Hero image={heroImage} />
        <Intro image={introImage} />
      </div>

      <div className="flex flex-col items-center m-auto bg-white px-2 max-w-2xl">
        <Accomodations image={sectionImage} />

        <Events />
        <Rsvp />
      </div>
    </Layout>
  )
}

const useImageData = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        section: file(relativePath: { eq: "red-agave-grounds.webp" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        intro: file(relativePath: { eq: "kids.jpg" }) {
          childImageSharp {
            fluid(quality: 75, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return {
    heroImage: data.hero.childImageSharp.fluid,
    introImage: data.intro.childImageSharp.fluid,
    sectionImage: data.section.childImageSharp.fluid,
  }
}

export default IndexPage
